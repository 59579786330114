import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import Feedback from '../components/feedback'

import styles from '../components/article.module.scss'

import Collapsible from '../components/collapsible-element'

import LeadSection from '../components/lead-section'


import RichTextRenderer from '../components/rich-text-renderer'

import { Location } from '@reach/router'

import MetadataGenerator from '../components/metadata-generator'
import ReactCollapsible from 'react-collapsible';






class ArticleTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.articleRef = React.createRef();
  }


  scrollToMyRef() {
     // Explicitly focus the text input using the raw DOM API
     // Note: we're accessing "current" to get the DOM node
     //this.textInput.current.focus();
     window.scrollTo(0, this.articleRef.current.offsetTop) ;
   }
   componentDidMount() {
    if (this.props.location) {
      var locationProps = this.props.location
      if (locationProps.state && locationProps.state.fromSearch) {
           //console.log("From search!");
           this.scrollToMyRef;  

      }
    } 
   }
  render() {

  //  if (this.props.location) {
  //   if(this.props.location.state.fromSearch) {
  //     console.log(this.articleRef.current);  
  //   }
  // }

  const post = get(this.props, 'data.contentfulSupportSiteArticle')
  const siteTitle = get(this.props, 'data.site.siteMetadata.title')
  const siteUrl = get(this.props, 'data.site.siteMetadata.siteUrl')    

  const headerImageTest = get(this, 'props.data.contentfulAsset');


  var numbered;

  var productSlug = false;

  var largeArticle = false;

  var leadLength = 0;

    //check eyebrow
    var eyebrow = '';

    if(post.productReference) {
      eyebrow = "A "+post.productReference.title+" FAQ"
      productSlug = post.productReference.slug

    }
    if(post.productReference && post.articleCategoryTopic && post.articleCategoryTopic.slug == 'software-updates') {
      eyebrow = "A "+post.productReference.title+" Update"
    }



    //check arrticle contents

    var openingHTML = ''
    var openingHTMLUpdate = '' 
    var articleStepHTML = ''
    var articleStepHTMLNew = ''

    var articleContent = ''
    var articleContentLead = ''
    var articleClass = 'article'

    var panelID = 'accordion'
    var panelRole = 'tablist'
    var panelAria = 'true'

    var manufacturerPlatform = false;
    var updateDate = false

    var articleItemProp = false;
    var articleItemPropElement = '';
    
    var stepsItemProp = false;
    var stepsItemPropElement = '';

    var updateHeading = "no"

    var leadSectionTitle = post.title

    var metaDescription = 'NextGen Youview Support Site'

    var articleTags = ''

    if(this.props.data.contentfulSupportSiteArticle.articleTags) {
      var longString = ''
      post.articleTags.map((tag) => (
        longString += " "+tag
        )) 
      articleTags = longString
    }

    if (post.manufacturerPlatform) {
      manufacturerPlatform = post.manufacturerPlatform
    }
    if (post.updateDate) {
      updateDate = post.updateDate
    }

    var containerClass = styles.accordionSection

    if(post.articleBody) {
      articleContent = post.articleBody.json
      articleContentLead = post.articleBody.json
      //console.log(post.articleBody.articleBody)
      leadLength = post.articleBody.articleBody.length
      // console.log('length')
      // console.log(leadLength)
    }

    if(!post.description) {
      openingHTML = ''
    }
    else if(post.articleCategoryTopic.slug == 'software-updates' && post.manufacturerPlatform) {
      openingHTML = ''
    }
    else {
      openingHTML = post.description.childMarkdownRemark.html
      //strip tags for meta
      metaDescription = openingHTML.replace(/<\/?[^>]+(>|$)/g, "");
    }


    if(!post.articleSteps) {
      articleStepHTML = '';
      articleContentLead = '';
      articleClass = styles.articleWide
      articleItemPropElement = "articleBody"
      articleItemProp = true;
      largeArticle = true


    }
    else {
      
      
        stepsItemProp = true;
        stepsItemPropElement = "articleBody"
        articleStepHTML = post.articleSteps.map((ContentfulArticleStep, i) => (
          <Collapsible numbered={numbered} iterator={i} key={ContentfulArticleStep.id} step={ContentfulArticleStep} />
          ))
        articleStepHTMLNew = post.articleSteps.map((ContentfulArticleStep, i) => {
          let stepTitleText = ContentfulArticleStep.title
          if(post.numberedSteps == true) {
            let iter = i + 1
            stepTitleText = iter + '. ' + stepTitleText;
          }
          let stepTitle = <><div className={"button is-icon-only accordion-toggle-cta-small"}><div className={"icon-small toggle_small w-embed"}><svg width="2" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M.447 16.932v-16h1v16h-1Z" fill="currentColor"></path></svg></div><div className={"icon-small is-overlapped toggle_small w-embed"}><svg width="2" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M.447 16.932v-16h1v16h-1Z" fill="currentColor"></path></svg></div></div><h2>{stepTitleText}</h2></>
          
          let triggerProps = {id: ContentfulArticleStep.id}
          return (
           <ReactCollapsible classParentString={styles.newCollapsible} key={ContentfulArticleStep.id} accordionPosition={ContentfulArticleStep.id} trigger={stepTitle} easing={'cubic-bezier(.25,.1,.25,1)'} triggerTagName={'a'} triggerElementProps={triggerProps} triggerClassName={styles.isClosed} triggerOpenedClassName={styles.isOpen} onOpen={() => logOpen(i,ContentfulArticleStep.id)}>
           
           <RichTextRenderer articleJSON={ContentfulArticleStep.stepContent.json} />

           </ReactCollapsible> 
          )
        })
        
          
      
      articleContent = ''

    }


    


    if(!post.numberedSteps) {
      numbered = '';
    }
    else if (post.numberedSteps == true) {
      containerClass = [styles.accordionSection, styles.numbered].join(" ")
    }
    else if (post.numberedSteps == false){
      numbered = '';
    }

  const logOpen = (index, id) => {
    // setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    let element = document.getElementById(id);
    element.scrollIntoView({behavior: 'smooth', block: 'start'});
    // console.log(index)
    // console.log(id)
    };
    
    return (
      <Layout headerImage={headerImageTest} location={this.props.location} title={post.title}>
      <MetadataGenerator 
        title={post.title} 
        siteTitle={siteTitle} 
        description={metaDescription} 
        urlBase={siteUrl}
        url={this.props.location.href}/>
      <section className={styles.dark}>
      <article role={"main"} itemScope={true}  itemType={"https://schema.org/TechArticle"}>
         
            
      <div ref={this.articleRef} className={"container"}>
      <LeadSection
      title={leadSectionTitle} 
      eyebrow={eyebrow} 
      subheading={openingHTML}
      description={articleContentLead}
      largeArticle={largeArticle}
      />
      <RichTextRenderer containerClass={articleClass} itemProp={articleItemProp ?
       articleItemPropElement : 
       undefined} articleJSON={articleContent} />


      </div>
      <div itemProp={stepsItemProp ?
       stepsItemPropElement : 
       undefined} className={containerClass} id={panelID} role={panelRole} aria-multiselectable={panelAria}>
      {articleStepHTMLNew}
      { /* articleStepHTML */}
      </div>
      <Feedback post={post}/>
      </article>
      </section>
      </Layout>
      )
  }

}

export default ArticleTemplate



export const pageQuery = graphql`
query ArticleBySlug($slug: String!) {
  site {
    siteMetadata {
      title
      siteUrl
    }
  }
  contentfulSupportSiteArticle(slug: { eq: $slug }) {
    title
    description {
      childMarkdownRemark {
        html
      }
    }
    articleBody {
      articleBody
      json
    }
    productReference {
      title
      slug
    }
    articleCategoryTopic {
      slug
      title
    }
    articleSteps {
      ... on ContentfulSupportSiteArticleStep {
        title
        id
        stepContent {
          json
        }  
      }
    }
    numberedSteps
    manufacturerPlatform {
      title
      logo {
        file {
          url
          details
          {
            image {
              width
              height
            }
          }
        }
      }
    }
    articleTags
    updateDate (formatString: "MMMM Do, YYYY")
  }
    contentfulAsset(file: {fileName: {eq: "youview-support-background.jpg"}}, fluid: {}) {
    fixed(width: 1800, quality: 100) {
      base64
      aspectRatio
      width
      height
      src
      srcSet
      srcWebp
      srcSetWebp
    }
  }
}
`
